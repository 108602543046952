import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { TemplateType } from '../../repositories/template_repository';
import { DietitianId } from '../dietitian';
import { TemplateField } from './template_field';
import { TemplateSynthesis } from './template-synthesis';

export interface TemplateProps {
  dietitianId?: DietitianId;
  name: string;
  locked?: boolean | undefined;
  type: TemplateType;
  createdAt?: Date;
  updatedAt?: Date;
  archivedAt?: Date;
  fields: TemplateField[];
  synthesis: TemplateSynthesis[];
  header: string;
  sign: string;
  sort?: number;
  codedFeatureId?: string;
}

export class TemplateId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): TemplateId {
    return new TemplateId(id);
  }
}

export class Template extends Entity<TemplateProps> {
  get templateId(): TemplateId {
    return TemplateId.create(this.id);
  }

  get dietitianId(): DietitianId | undefined {
    return this.props.dietitianId ?? undefined;
  }

  get name(): string {
    return this.props.name;
  }

  get locked(): boolean | undefined {
    return this.props.locked;
  }

  get type(): TemplateType {
    return this.props.type;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get archivedAt(): Date | undefined {
    return this.props.archivedAt;
  }

  get archived(): boolean {
    return this.props.archivedAt != null;
  }

  get fields(): TemplateField[] {
    return this.props.fields;
  }

  get synthesis(): TemplateSynthesis[] {
    return this.props.synthesis;
  }

  get header(): string {
    return this.props.header;
  }

  get sign(): string {
    return this.props.sign;
  }

  get sort(): number | undefined {
    return this.props.sort;
  }

  get codedFeatureId(): string | undefined {
    return this.props.codedFeatureId;
  }

  set codedFeatureId(value: string | undefined) {
    this.props.codedFeatureId = value;
  }

  private constructor(props: TemplateProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: TemplateProps): Template {
    return Template.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  archive(): Template {
    return this.copyWith({ archivedAt: new Date() } as TemplateProps);
  }

  restore(): Template {
    return this.copyWith({ archivedAt: undefined } as TemplateProps);
  }

  public static create(props: TemplateProps, id?: UniqueEntityID): Template {
    return new Template(props, id);
  }
}
