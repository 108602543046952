import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import * as Sentry from '@sentry/angular-ivy';
import { NgChartsModule } from 'ng2-charts';
import { DragulaModule } from 'ng2-dragula';
import { AutosizeModule } from 'ngx-autosize';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {
  arrow90DegUp,
  arrowDown,
  arrowDownUp,
  arrowLeftRight,
  arrowLeftShort,
  arrowRight,
  arrowRightShort,
  arrowUp,
  arrowUpRightSquare,
  asterisk,
  award,
  barChart,
  barChartFill,
  barChartLine,
  barChartLineFill,
  batteryFull,
  bodyText,
  bookmarkFill,
  boxArrowDown,
  boxArrowInDown,
  boxArrowInLeft,
  boxArrowRight,
  boxArrowUp,
  bracesAsterisk,
  calculator,
  calculatorFill,
  calendar,
  calendar3,
  calendarDate,
  calendarEvent,
  calendarHeart,
  cardText,
  caretRightSquare,
  check,
  check2Square,
  checkAll,
  checkCircleFill,
  checkSquare,
  checkSquareFill,
  chevronDown,
  chevronLeft,
  chevronRight,
  clockFill,
  cloudArrowUpFill,
  cloudCheck,
  creditCard,
  currencyEuro,
  dash,
  download,
  envelope,
  envelopeOpen,
  exclamationCircleFill,
  exclamationDiamondFill,
  eye,
  fileEarmark,
  fileEarmarkBreak,
  fileEarmarkCheck,
  fileEarmarkPost,
  fileEarmarkPpt,
  fileLock,
  fileLockFill,
  filePdf,
  fileSpreadsheet,
  filetypeDoc,
  filter,
  flag,
  flagFill,
  gear,
  gearFill,
  genderAmbiguous,
  genderFemale,
  genderMale,
  graphUp,
  grid3X2GapFill,
  heartPulseFill,
  icon123,
  image,
  infoCircle,
  infoCircleFill,
  infoSquare,
  journalBookmarkFill,
  link45Deg,
  lock,
  lockFill,
  mic,
  NgxBootstrapIconsModule,
  paperclip,
  pauseFill,
  pen,
  pencil,
  pencilFill,
  pencilSquare,
  penFill,
  pentagon,
  personCircle,
  personDash,
  personPlus,
  phone,
  pieChart,
  pieChartFill,
  playFill,
  plus,
  plusCircleFill,
  plusSquare,
  plusSquareDotted,
  plusSquareFill,
  printer,
  question,
  questionCircle,
  questionLg,
  questionSquare,
  recordCircle,
  search,
  send,
  sliders,
  square,
  star,
  starFill,
  starHalf,
  stars,
  stopFill,
  threeDots,
  trash,
  trashFill,
  trophy,
  unlockFill,
  viewStacked,
  wallet2,
  x,
  xCircle,
  xCircleFill,
  xSquare,
} from 'ngx-bootstrap-icons';
import { NgxPrinterModule } from 'ngx-printer';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppFacade } from './app.facade';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/error/global-error-handler';
import { AuthModule } from './data/auth/auth.module';
import { CompositionModule } from './data/composition/composition.module';
import { DietitianModule } from './data/dietitian/dietitian.module';
import { DocumentModule } from './data/document/document.module';
import { EmailModule } from './data/email/email.module';
import { LoggerRepository } from './data/logger/repositories/logger_repository';
import { MessageModule } from './data/message/message.module';
import { MsdpModule } from './data/msdp/msdp.module';
import { NotificationModule } from './data/notification/notification.module';
import { PatientModule } from './data/patient/patient.module';
import { AuthenticationGuard } from './ui/authentication/authentication.guard';
import { NewsPopupModule } from './ui/components/news-popup/news-popup.module';
import { SubscriptionBannerModule } from './ui/components/subscription-banner/subscription-banner.module';
import { DashboardGuard } from './ui/dashboard/dashboard.guard';
import { DocumentGuard } from './ui/document/document.guard';
import { DocumentIdGuard } from './ui/document/document-id.guard';
import { NutritionGuard } from './ui/nutrition/nutrition.guard';
import { NutritionIdGuard } from './ui/nutrition/nutrition-id.guard';
import { PatientGuard } from './ui/patient/patient.guard';
import { TemplateGuard } from './ui/template/template.guard';
import { TemplateIdGuard } from './ui/template/template-id.guard';

const icons = {
  icon123,
  boxArrowDown,
  boxArrowInDown,
  boxArrowUp,
  arrowDownUp,
  checkSquare,
  download,
  chevronLeft,
  creditCard,
  wallet2,
  chevronRight,
  plus,
  pauseFill,
  plusSquare,
  threeDots,
  trash,
  xSquare,
  phone,
  check,
  arrowLeftShort,
  search,
  arrowRightShort,
  pencil,
  pencilSquare,
  playFill,
  pen,
  questionSquare,
  questionCircle,
  send,
  x,
  eye,
  stopFill,
  envelopeOpen,
  check2Square,
  barChart,
  sliders,
  infoCircle,
  bodyText,
  calendar,
  calendarEvent,
  calendarDate,
  calendarHeart,
  graphUp,
  printer,
  trophy,
  personPlus,
  envelope,
  mic,
  lockFill,
  image,
  paperclip,
  fileEarmark,
  checkAll,
  cloudArrowUpFill,
  cloudCheck,
  plusCircleFill,
  boxArrowInLeft,
  unlockFill,
  asterisk,
  pentagon,
  gearFill,
  barChartLine,
  bracesAsterisk,
  xCircle,
  xCircleFill,
  filePdf,
  award,
  infoSquare,
  barChartLineFill,
  barChartFill,
  cardText,
  link45Deg,
  checkCircleFill,
  caretRightSquare,
  boxArrowRight,
  arrowUpRightSquare,
  arrow90DegUp,
  recordCircle,
  pencilFill,
  filetypeDoc,
  trashFill,
  genderMale,
  genderFemale,
  genderAmbiguous,
  question,
  questionLg,
  pieChart,
  flag,
  flagFill,
  pieChartFill,
  batteryFull,
  heartPulseFill,
  chevronDown,
  grid3X2GapFill,
  checkSquareFill,
  gear,
  square,
  star,
  starFill,
  filter,
  arrowUp,
  arrowDown,
  bookmarkFill,
  personCircle,
  infoCircleFill,
  currencyEuro,
  personDash,
  calendar3,
  fileEarmarkBreak,
  fileEarmarkCheck,
  clockFill,
  fileEarmarkPpt,
  fileEarmarkPost,
  journalBookmarkFill,
  starHalf,
  stars,
  fileSpreadsheet,
  exclamationCircleFill,
  arrowLeftRight,
  plusSquareDotted,
  plusSquareFill,
  arrowRight,
  dash,
  viewStacked,
  calculator,
  calculatorFill,
  exclamationDiamondFill,
  fileLock,
  fileLockFill,
  penFill,
  lock,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    NgxBootstrapIconsModule.pick(icons),
    AuthModule.forRoot(),
    DietitianModule.forRoot(),
    MsdpModule.forRoot(),
    PatientModule.forRoot(),
    DocumentModule.forRoot(),
    CompositionModule.forRoot(),
    MessageModule.forRoot(),
    NotificationModule.forRoot(),
    EmailModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DragulaModule.forRoot(),
    CarouselModule.forRoot(),
    ToastrModule.forRoot({
      progressBar: true,
      positionClass: 'toast-bottom-right',
    }),
    CoreModule,
    CollapseModule.forRoot(),
    NgChartsModule.forRoot(),
    ProgressbarModule.forRoot(),
    AutosizeModule,
    NgxSliderModule,
    SubscriptionBannerModule,
    NgxPrinterModule.forRoot({ printOpenWindow: false }),
    FormsModule,
    NewsPopupModule,
  ],
  providers: [
    AppFacade,
    DashboardGuard,
    AuthenticationGuard,
    PatientGuard,
    TemplateGuard,
    TemplateIdGuard,
    NutritionGuard,
    NutritionIdGuard,
    DocumentGuard,
    DocumentIdGuard,
    LoggerRepository,
    { provide: REGION, useValue: 'europe-west1' },
    {
      provide: GlobalErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
