import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OrderByDirection, Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import {
  SynthesisFormula,
  SynthesisType,
} from '../../../ui/template/routes/editor/components/editor-synthesis-element/synthesis-model';
import { FieldType } from '../../../ui/template/routes/editor/components/fields-library/field_type';
import { DietitianId } from '../domain/dietitian';
import { Template, TemplateId } from '../domain/templates/template';
import { TemplateNotFoundException } from '../domain/templates/template_exceptions';
import {
  TemplateField,
  TemplateFieldId,
} from '../domain/templates/template_field';
import {
  TemplateSynthesis,
  TemplateSynthesisId,
} from '../domain/templates/template-synthesis';
import { TemplateSynthesisDataset } from '../domain/templates/template-synthesis-dataset';

export enum TemplateType {
  Note = 'note',
  Survey = 'survey',
}

export interface TemplateSchema {
  name: string;
  locked: boolean | null;
  type: TemplateType | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  archivedAt: Timestamp | null;
  fields?: TemplateFieldSchema[];
  synthesis?: TemplateSynthesisSchema[];
  header?: string | null;
  sign?: string | null;
  sort?: number | null;
  codedFeatureId?: string | null;
}

export interface TemplateFieldSchema {
  id: string;
  label: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
  type: FieldType;
  params: unknown | null;
  order: number;
  locked: boolean;
  editable: boolean | null;
}

export interface TemplateSynthesisSchema {
  id: string;
  name: string;
  commentDietitian?: string | null;
  commentPatient?: string | null;
  source?: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
  type: SynthesisType;
  dataset?: TemplateSynthesisDatasetSchema[];
}

export interface TemplateSynthesisDatasetSchema {
  id: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
  values: string[];
  formula: SynthesisFormula;
}

@Injectable()
export class TemplateRepository {
  public previewTemplateFromCloud: (data: unknown) => Observable<string>;

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.previewTemplateFromCloud = this.functions.httpsCallable<
      unknown,
      string
    >('template-preview');
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<TemplateSchema>('notes_templates', queryFn);
  }

  toSchema(template: Template): TemplateSchema {
    const schema = <TemplateSchema>{
      name: template.name,
      type: template.type ?? TemplateType.Note,
      locked: template.locked ?? false,
      createdAt:
        template.createdAt !== undefined
          ? Timestamp.fromDate(template.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      archivedAt:
        template.archivedAt !== undefined
          ? Timestamp.fromDate(template.archivedAt)
          : null,
      fields: template.fields
        .map(
          (f) =>
            <TemplateFieldSchema>{
              id: f.id.toString(),
              label: f.label,
              createdAt:
                f.createdAt !== undefined
                  ? Timestamp.fromDate(f.createdAt)
                  : Timestamp.now(),
              updatedAt: Timestamp.now(),
              deletedAt:
                f.deletedAt !== undefined
                  ? Timestamp.fromDate(f.deletedAt)
                  : null,
              type: f.type,
              params: f.params ?? null,
              order: f.order,
              locked: f.locked ?? false,
              editable: f.editable ?? true,
            },
        )
        .sort((a, b) => a.order - b.order),
      synthesis: template.synthesis.map(
        (s) =>
          <TemplateSynthesisSchema>{
            id: s.id.toString(),
            name: s.name,
            commentDietitian: s.commentDietitian ?? null,
            commentPatient: s.commentPatient ?? null,
            source: s.source ?? null,
            createdAt:
              s.createdAt !== undefined
                ? Timestamp.fromDate(s.createdAt)
                : Timestamp.now(),
            updatedAt: Timestamp.now(),
            deletedAt:
              s.deletedAt !== undefined
                ? Timestamp.fromDate(s.deletedAt)
                : null,
            type: s.type,
            dataset: s.templateSynthesisDataset
              ? s.templateSynthesisDataset.map(
                  (ds) =>
                    <TemplateSynthesisDatasetSchema>{
                      id: ds.id.toString(),
                      name: ds.name,
                      createdAt:
                        ds.createdAt !== undefined
                          ? Timestamp.fromDate(ds.createdAt)
                          : Timestamp.now(),
                      updatedAt: Timestamp.now(),
                      deletedAt:
                        ds.deletedAt !== undefined
                          ? Timestamp.fromDate(ds.deletedAt)
                          : null,
                      formula: ds.formula,
                      values: ds.values
                        ? ds.values.map((v) => v.id.toString())
                        : null,
                    },
                )
              : null,
          },
      ),
      header: template.header,
      sign: template.sign,
      sort: template.sort ?? null,
      codedFeatureId: template.codedFeatureId ?? null,
    };

    // FIXME: use Set in order to remove duplicates
    if (schema.fields) {
      const uniqueIds: string[] = [];
      schema.fields = schema.fields.filter((field) => {
        const isDuplicate = uniqueIds.includes(field.id);
        if (!isDuplicate) {
          uniqueIds.push(field.id);
          return true;
        }
        return false;
      });
    }
    return schema;
  }

  fromSchema(
    schema: TemplateSchema,
    dietitianId: string | undefined,
    id?: string,
  ): Template {
    return Template.create(
      {
        dietitianId: dietitianId
          ? DietitianId.create(new UniqueEntityID(dietitianId))
          : undefined,
        name: schema.name,
        locked: schema.locked ?? false,
        type: schema.type ?? TemplateType.Note,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        archivedAt: schema.archivedAt?.toDate(),
        fields:
          schema.fields
            ?.map((f) =>
              TemplateField.create(
                {
                  templateId: TemplateId.create(new UniqueEntityID(id)),
                  label: f.label,
                  createdAt: f.createdAt.toDate(),
                  updatedAt: f.updatedAt.toDate(),
                  deletedAt: f.deletedAt?.toDate(),
                  type: f.type,
                  params: f.params,
                  order: f.order,
                  locked: f.locked ?? false,
                  editable: f.editable === null ? true : f.editable,
                },
                new UniqueEntityID(f.id),
              ),
            )
            .sort((a, b) => a.order - b.order) ?? [],
        synthesis:
          schema.synthesis?.map((s) =>
            TemplateSynthesis.create(
              {
                templateId: TemplateId.create(new UniqueEntityID(id)),
                name: s.name,
                commentDietitian: s.commentDietitian ?? undefined,
                commentPatient: s.commentPatient ?? undefined,
                source: s.source ?? undefined,
                createdAt: s.createdAt.toDate(),
                updatedAt: s.updatedAt.toDate(),
                deletedAt: s.deletedAt?.toDate(),
                type: s.type,
                dataset: s.dataset?.map((ds) =>
                  TemplateSynthesisDataset.create(
                    {
                      templateSynthesisId: TemplateSynthesisId.create(
                        new UniqueEntityID(s.id),
                      ),
                      name: ds.name,
                      createdAt: ds.createdAt.toDate(),
                      updatedAt: ds.updatedAt.toDate(),
                      deletedAt: ds.deletedAt?.toDate(),
                      formula: ds.formula,
                      values: ds.values.map((vId) =>
                        TemplateFieldId.create(new UniqueEntityID(vId)),
                      ),
                    },
                    new UniqueEntityID(ds.id),
                  ),
                ),
              },
              new UniqueEntityID(s.id),
            ),
          ) ?? [],
        header: schema.header ?? '',
        sign: schema.sign ?? '',
        sort: schema.sort ?? undefined,
        codedFeatureId: schema.codedFeatureId ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(dietitianId: string, templateId: string): Promise<Template> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(templateId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new TemplateNotFoundException();
    }
    return this.fromSchema(snap.data() as TemplateSchema, dietitianId, snap.id);
  }

  async existsForDietitianIdAndName(
    dietitianId: string,
    name: string,
    templateId?: string,
  ): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('name', '==', name).where('archivedAt', '==', null),
      ).get(),
    );

    return snap.size !== 0 && snap.docs.some((doc) => doc.id !== templateId);
  }

  async findByDietitianId(dietitianId: string): Promise<Template[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => ref.orderBy('name')).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findActiveTemplatesByDietitianId(
    dietitianId: string,
  ): Promise<Template[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('archivedAt', '==', null).orderBy('name'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findActiveTemplatesByDietitianIdFilteredAndSorted(
    dietitianId: string,
    type: string,
    orderBy: string,
    direction: string,
  ): Promise<Template[]> {
    let snap;
    if (type !== 'all') {
      if (type == 'survey') {
        snap = await firstValueFrom(
          this.collection(dietitianId, (ref) =>
            ref
              .where('archivedAt', '==', null)
              .where('type', '==', 'survey')
              .orderBy(orderBy, direction as OrderByDirection),
          ).get(),
        );
      } else {
        snap = await firstValueFrom(
          this.collection(dietitianId, (ref) =>
            ref
              .where('archivedAt', '==', null)
              .where('type', '!=', 'survey')
              .orderBy('type', 'asc')
              .orderBy(orderBy, direction as OrderByDirection),
          ).get(),
        );
      }
    } else {
      snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref
            .where('archivedAt', '==', null)
            .orderBy(orderBy, direction as OrderByDirection),
        ).get(),
      );
    }

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findArchivedTemplatesByDietitianId(
    dietitianId: string,
  ): Promise<Template[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref
          .where('archivedAt', '!=', null)
          .orderBy('archivedAt')
          .orderBy('name'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findArchivedTemplatesByDietitianIdFilteredAndSorted(
    dietitianId: string,
    type: string,
    orderBy: string,
    direction: string,
  ): Promise<Template[]> {
    let snap;
    if (type !== 'all') {
      if (type == 'survey') {
        snap = await firstValueFrom(
          this.collection(dietitianId, (ref) =>
            ref
              .where('archivedAt', '!=', null)
              .where('type', '==', 'survey')
              .orderBy('archivedAt', 'asc')
              .orderBy(orderBy, direction as OrderByDirection),
          ).get(),
        );
      } else {
        snap = await firstValueFrom(
          this.collection(dietitianId, (ref) =>
            ref
              .where('archivedAt', '!=', null)
              .where('type', '!=', 'survey')
              .orderBy('type', 'asc')
              .orderBy('archivedAt', 'asc')
              .orderBy(orderBy, direction as OrderByDirection),
          ).get(),
        );
      }
    } else {
      snap = await firstValueFrom(
        this.collection(dietitianId, (ref) =>
          ref
            .where('archivedAt', '!=', null)
            .orderBy('archivedAt', 'asc')
            .orderBy(orderBy, direction as OrderByDirection),
        ).get(),
      );
    }

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async create(template: Template): Promise<Template> {
    const schema = this.toSchema(template);
    if (template.dietitianId) {
      const ref = await this.collection(template.dietitianId.id.toString()).add(
        schema,
      );
      return this.fromSchema(
        schema,
        template.dietitianId.id.toString(),
        ref.id,
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async save(template: Template): Promise<Template> {
    const schema = this.toSchema(template);
    if (template.dietitianId) {
      const dietitianId = template.dietitianId.id.toString();
      await this.collection(dietitianId)
        .doc(template.templateId.id.toString())
        .set(schema);
      return this.fromSchema(
        schema,
        dietitianId,
        template.templateId.id.toString(),
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  // Bibliothèque de templates -------------------------------------------------
  private collectionLibrary(queryFn?: QueryFn) {
    return this.firestore.collection<TemplateSchema>(
      'templates_library',
      queryFn,
    );
  }

  async existsInLibrary(name: string, templateId?: string): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collectionLibrary((ref) => ref.where('name', '==', name)).get(),
    );

    return snap.size !== 0 && snap.docs.some((doc) => doc.id !== templateId);
  }

  async findTemplatesFromLibrary(
    codedFeatureId: string | undefined,
  ): Promise<Template[]> {
    const snap = await firstValueFrom(
      this.collectionLibrary((ref) =>
        ref
          .where('archivedAt', '==', null)
          .where('codedFeatureId', '==', codedFeatureId ?? null),
      ).get(),
    );
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), undefined, doc.id),
    );
  }

  async createInLibrary(template: Template): Promise<Template> {
    const schema = this.toSchema(template);
    const ref = await this.collectionLibrary().add(schema);
    return this.fromSchema(schema, undefined, ref.id);
  }

  async loadFromLibrary(templateId: string) {
    const snap = await firstValueFrom(
      this.collectionLibrary().doc(templateId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new TemplateNotFoundException();
    }
    return this.fromSchema(snap.data() as TemplateSchema, undefined, snap.id);
  }

  async countFromLibrary(): Promise<number> {
    const snap = await firstValueFrom(
      this.collectionLibrary((ref) =>
        ref.where('archivedAt', '==', null),
      ).get(),
    );
    return snap.docs.length;
  }
}
