import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { FieldType } from '../../../../ui/template/routes/editor/components/fields-library/field_type';
import { TemplateId } from './template';

export interface TemplateFieldProps {
  templateId: TemplateId;
  label: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  type: FieldType;
  params: unknown;
  order: number;
  locked: boolean;
  editable: boolean;
}

export class TemplateFieldId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): TemplateFieldId {
    return new TemplateFieldId(id);
  }
}

export class TemplateField extends Entity<TemplateFieldProps> {
  get templateFieldId(): TemplateFieldId {
    return TemplateFieldId.create(this.id);
  }

  get templateId(): TemplateId {
    return this.props.templateId;
  }

  get label(): string {
    return this.props.label;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get deletedAt(): Date | undefined {
    return this.props.deletedAt;
  }

  get type(): FieldType {
    return this.props.type;
  }

  get params(): unknown {
    return this.props.params;
  }

  get order(): number {
    return this.props.order;
  }

  get locked(): boolean {
    return this.props.locked;
  }

  get editable(): boolean {
    return this.props.editable;
  }

  private constructor(props: TemplateFieldProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: TemplateFieldProps): TemplateField {
    return TemplateField.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: TemplateFieldProps,
    id?: UniqueEntityID,
  ): TemplateField {
    return new TemplateField(props, id);
  }
}
