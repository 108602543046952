import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export enum CodedFeatureStatus {
  Active = 'active',
  Disable = 'disable',
}

export interface CodedFeatureProps {
  label: string;
  description: string;
  icon: string;
  color: string;
  backgroundColor: string;
  status: CodedFeatureStatus;
  createdAt: Date;
  updatedAt?: Date | undefined;
}

export class CodedFeatureId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): CodedFeatureId {
    return new CodedFeatureId(id);
  }
}

export class CodedFeature extends Entity<CodedFeatureProps> {
  get featureId(): CodedFeatureId {
    return CodedFeatureId.create(this.id);
  }

  get label(): string {
    return this.props.label;
  }

  get description(): string {
    return this.props.description;
  }

  get icon(): string {
    return this.props.icon;
  }

  get color(): string {
    return this.props.color;
  }

  get backgroundColor(): string {
    return this.props.backgroundColor;
  }

  get status(): CodedFeatureStatus {
    return this.props.status;
  }

  get createdAt(): Date {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: CodedFeatureProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: CodedFeatureProps): CodedFeature {
    return CodedFeature.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: CodedFeatureProps,
    id?: UniqueEntityID,
  ): CodedFeature {
    return new CodedFeature(props, id);
  }
}
