import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { SharedDocumentId } from '../../../document/domain/shared_document/shared_document';
import { PatientId } from '../../../patient/domain/patient';
import { DietitianId } from '../dietitian';
import { ThirdParty } from '../third_party/third-party';
import { EstimateLine } from './estimate-line';

export enum EstimateStatus {
  DRAFT = 'DRAFT',
  EMITTED = 'EMITTED',
  TRANSFORMED = 'TRANSFORMED',
}

export interface EstimateProps {
  dietitianId: DietitianId;
  patientId: PatientId | undefined;
  thirdParty: ThirdParty | undefined;
  referencePrefix?: string | undefined;
  reference?: number | undefined;
  estimateAt: Date | undefined;
  patientFullname: string | undefined;
  lines: EstimateLine[];
  amountExclTax: number;
  amountInclTax: number;
  amountTax: number;
  rateTax: number;
  withTax: boolean;
  expireAt?: Date | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
  deleted: boolean;
  deletedAt?: Date | undefined;
  url?: string | undefined;
  path?: string | undefined;
  documentHasReady: boolean;
  status: EstimateStatus;
  transformedAt?: Date | undefined;
  sharedDocumentId?: SharedDocumentId | undefined;
  sharedAt?: Date | undefined;
}

export class EstimateId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): EstimateId {
    return new EstimateId(id);
  }
}

export class Estimate extends Entity<EstimateProps> {
  get estimateId(): EstimateId {
    return EstimateId.create(this.id);
  }

  get dietitianId(): DietitianId | undefined {
    return this.props.dietitianId ?? undefined;
  }

  get patientId(): PatientId | undefined {
    return this.props.patientId;
  }

  set patientId(value: PatientId | undefined) {
    this.props.patientId = value;
  }

  get thirdParty(): ThirdParty | undefined {
    return this.props.thirdParty;
  }

  set thirdParty(value: ThirdParty | undefined) {
    this.props.thirdParty = value;
  }

  get referencePrefix(): string | undefined {
    return this.props.referencePrefix;
  }

  set referencePrefix(value: string | undefined) {
    this.props.referencePrefix = value;
  }

  get reference(): number | undefined {
    return this.props.reference;
  }

  get estimateAt(): Date | undefined {
    return this.props.estimateAt;
  }

  set estimateAt(value: Date | undefined) {
    this.props.estimateAt = value;
  }

  get patientFullname(): string | undefined {
    return this.props.patientFullname;
  }

  set patientFullname(value: string | undefined) {
    this.props.patientFullname = value;
  }

  get lines(): EstimateLine[] {
    return this.props.lines;
  }

  get amountExclTax(): number {
    return this.props.amountExclTax;
  }

  get amountInclTax(): number {
    return this.props.amountInclTax;
  }

  get amountTax(): number {
    return this.props.amountTax;
  }

  get rateTax(): number {
    return this.props.rateTax;
  }

  get withTax(): boolean {
    return this.props.withTax;
  }

  get expireAt(): Date | undefined {
    return this.props.expireAt;
  }

  set expireAt(value: Date | undefined) {
    this.props.expireAt = value;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get deleted(): boolean {
    return this.props.deleted;
  }

  get deletedAt(): Date | undefined {
    return this.props.deletedAt;
  }

  get url(): string | undefined {
    return this.props.url;
  }

  set url(value: string | undefined) {
    this.props.url = value;
  }

  get path(): string | undefined {
    return this.props.path;
  }

  set path(value: string | undefined) {
    this.props.path = value;
  }

  get documentHasReady(): boolean {
    return this.props.documentHasReady;
  }

  set documentHasReady(value: boolean) {
    this.props.documentHasReady = value;
  }

  get status(): EstimateStatus {
    return this.props.status;
  }

  set status(value: EstimateStatus) {
    this.props.status = value;
  }

  get transformedAt(): Date | undefined {
    return this.props.transformedAt;
  }

  get sharedDocumentId(): SharedDocumentId | undefined {
    return this.props.sharedDocumentId;
  }

  set sharedDocumentId(value: SharedDocumentId | undefined) {
    this.props.sharedDocumentId = value;
  }

  get sharedAt(): Date | undefined {
    return this.props.sharedAt;
  }

  set sharedAt(value: Date | undefined) {
    this.props.sharedAt = value;
  }

  private constructor(props: EstimateProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: EstimateProps): Estimate {
    return Estimate.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: EstimateProps, id?: UniqueEntityID): Estimate {
    return new Estimate(props, id);
  }
}
