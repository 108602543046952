import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { TemplateRepository } from '../../repositories/template_repository';
import { DietitianId } from '../dietitian';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { TemplatesEventProvider } from '../events/notes_templates/notes_templates_event_provider';
import {
  TemplateArchived,
  TemplateCreated,
  TemplateExported,
  TemplateImported,
  TemplateRestored,
  TemplateUpdated,
} from '../events/notes_templates/notes_templates_events';
import { Template, TemplateProps } from './template';
import { TemplateExistingNameException } from './template_exceptions';

@Injectable()
export class TemplateCommands {
  constructor(
    private repository: TemplateRepository,
    private dietitianStateProvider: DietitianStateProvider,
    private eventProvider: TemplatesEventProvider,
    private toastr: ToastrService,
  ) {
    this.eventProvider.events$.subscribe((event) => {
      if (event instanceof TemplateCreated) {
        this.toastr.success('Template créé');
      } else if (event instanceof TemplateUpdated) {
        this.toastr.success('Template mis à jour');
      } else if (event instanceof TemplateArchived) {
        this.toastr.success('Template désactivé');
      } else if (event instanceof TemplateRestored) {
        this.toastr.success('Template activé');
      }
    });
  }

  async getTemplate(
    dietitianId: string,
    templateId: string,
  ): Promise<Template> {
    return this.repository.load(dietitianId, templateId);
  }

  async getDietitianTemplates(dietitianId: string): Promise<Template[]> {
    return this.repository.findByDietitianId(dietitianId);
  }

  async getDietitianActiveTemplates(dietitianId: string): Promise<Template[]> {
    return this.repository.findActiveTemplatesByDietitianId(dietitianId);
  }

  async getDietitianActiveTemplatesFilteredAndSorted(
    dietitianId: string,
    type: string,
    orderBy: string,
    direction: string,
  ): Promise<Template[]> {
    return this.repository.findActiveTemplatesByDietitianIdFilteredAndSorted(
      dietitianId,
      type,
      orderBy,
      direction,
    );
  }

  async getDietitianArchivedTemplates(
    dietitianId: string,
  ): Promise<Template[]> {
    return this.repository.findArchivedTemplatesByDietitianId(dietitianId);
  }

  async getDietitianArchivedTemplatesFilteredAndSorted(
    dietitianId: string,
    type: string,
    orderBy: string,
    direction: string,
  ): Promise<Template[]> {
    return this.repository.findArchivedTemplatesByDietitianIdFilteredAndSorted(
      dietitianId,
      type,
      orderBy,
      direction,
    );
  }

  async saveTemplate(
    templateProps: TemplateProps,
    templateId?: string,
  ): Promise<Template> {
    if (templateProps.dietitianId) {
      const exists = await this.repository.existsForDietitianIdAndName(
        templateProps.dietitianId.id.toString(),
        templateProps.name,
        templateId,
      );
      if (exists) {
        throw new TemplateExistingNameException(templateProps.name);
      }
      let template = Template.create(
        templateProps,
        new UniqueEntityID(templateId),
      );
      if (templateId !== undefined) {
        template = await this.repository.save(template);
        this.eventProvider.dispatch(new TemplateUpdated(template));
      } else {
        template = await this.repository.create(template);
        this.eventProvider.dispatch(new TemplateCreated(template));
      }
      return template;
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async archiveTemplate(
    dietitianId: string,
    templateId: string,
  ): Promise<Template> {
    let template = (
      await this.repository.load(dietitianId, templateId)
    ).archive();
    template = await this.repository.save(template);
    this.eventProvider.dispatch(new TemplateArchived(template));
    return template;
  }

  async restoreTemplate(
    dietitianId: string,
    templateId: string,
  ): Promise<Template> {
    let template = (
      await this.repository.load(dietitianId, templateId)
    ).restore();
    template = await this.repository.save(template);
    this.eventProvider.dispatch(new TemplateRestored(template));
    return template;
  }

  async duplicate(template: Template): Promise<Template> {
    const templateProps = template.copyWith(template.props).props;
    const newName = 'Duplicata de "' + templateProps.name + '"';
    if (templateProps.dietitianId) {
      const exists = await this.repository.existsForDietitianIdAndName(
        templateProps.dietitianId.id.toString(),
        newName,
      );
      if (exists) {
        throw new TemplateExistingNameException(newName);
      }

      templateProps.name = newName;
      templateProps.archivedAt = undefined;
      templateProps.updatedAt = undefined;
      templateProps.createdAt = new Date();

      let duplicatedTemplate = Template.create(
        templateProps,
        new UniqueEntityID(),
      );
      duplicatedTemplate = await this.repository.create(duplicatedTemplate);
      this.eventProvider.dispatch(new TemplateCreated(duplicatedTemplate));
      return duplicatedTemplate;
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  // Gestion de la bibliothèque de templates
  async getTemplatesLibrary(
    codedFeatureId: string | undefined,
  ): Promise<Template[]> {
    return this.repository.findTemplatesFromLibrary(codedFeatureId);
  }

  async importFromLibrary(
    template: Template,
    dietitianId: DietitianId,
  ): Promise<Template> {
    let idx = 1;
    let exists = false;
    let importedTemplate: Template;
    do {
      importedTemplate = template.copyWith({
        archivedAt: undefined,
        updatedAt: undefined,
        createdAt: new Date(),
        dietitianId: dietitianId,
        name:
          template.name + ' (Importé n°' + idx++ + ' depuis la bibliothèque)',
      } as TemplateProps);
      exists = await this.repository.existsForDietitianIdAndName(
        dietitianId.id.toString(),
        importedTemplate.name,
      );
    } while (exists);

    importedTemplate = await this.repository.create(importedTemplate);
    this.eventProvider.dispatch(new TemplateImported(importedTemplate));
    return importedTemplate;
  }

  async exportToLibrary(template: Template) {
    const templateProps = template.props;
    templateProps.archivedAt = undefined;
    templateProps.updatedAt = undefined;
    templateProps.createdAt = new Date();
    templateProps.dietitianId = undefined;
    const exists = await this.repository.existsInLibrary(templateProps.name);

    if (exists) {
      throw new TemplateExistingNameException(templateProps.name);
    }

    const count = await this.repository.countFromLibrary();
    templateProps.sort = count + 1;

    let exportedTemplate = Template.create(templateProps, new UniqueEntityID());
    exportedTemplate = await this.repository.createInLibrary(exportedTemplate);
    this.eventProvider.dispatch(new TemplateExported(exportedTemplate));
  }

  async getTemplateFromLibrary(templateId: string): Promise<Template> {
    return await this.repository.loadFromLibrary(templateId);
  }

  async previewTemplate(template: Template): Promise<string | undefined> {
    const data = {
      template,
    };
    return firstValueFrom<string | undefined>(
      this.repository.previewTemplateFromCloud(data),
    );
  }
}
