import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import {
  CodedFeature,
  CodedFeatureStatus,
} from '../domain/subscription/coded_feature';

export interface CodedFeatureSchema {
  label: string;
  description: string;
  icon: string;
  color: string;
  backgroundColor: string;
  status: CodedFeatureStatus;
  createdAt: Timestamp;
  updatedAt?: Timestamp | null;
}

@Injectable()
export class CodedFeatureRepository {
  constructor(private firestore: AngularFirestore) {
    // Nada
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<CodedFeatureSchema>(
      'coded_features',
      queryFn,
    );
  }

  fromSchema(schema: CodedFeatureSchema, id: string): CodedFeature {
    return CodedFeature.create(
      {
        label: schema.label,
        description: schema.description,
        icon: schema.icon,
        color: schema.color,
        backgroundColor: schema.backgroundColor,
        status: schema.status,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async findAllActive(): Promise<CodedFeature[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref.where('status', '==', CodedFeatureStatus.Active),
      ).get(),
    );
    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async validCodedFeature(
    codedFeatureId: string,
    code: string,
  ): Promise<boolean> {
    console.log('☢️ validCodedFeature Code', { codedFeatureId, code });
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('status', '==', CodedFeatureStatus.Active)
          .where('code', '==', code),
      ).get(),
    );
    return snap.docs.filter((doc) => doc.id === codedFeatureId).length > 0;
  }
}
